import {atom, AtomEffect, DefaultValue} from 'recoil';

export interface GuestStateProps {
  name: string;
  calendar: string;
  gender: string;
  birthday: string;
  birthtime: string;
  ampm_unsure: string;
  locationId: string;
}

const DEFAULT_VALUE: GuestStateProps = {
  name: '',
  calendar: '',
  gender: '',
  birthday: '',
  birthtime: '',
  ampm_unsure: '',
  locationId: '',
};

function fromLocalStorageEffect(key: string): AtomEffect<GuestStateProps> {
  const storage: Storage = window.localStorage;

  const getValue = (): typeof DEFAULT_VALUE => {
    const state = storage.getItem(key);

    if (typeof state === 'string') {
      try {
        return JSON.parse(decodeURIComponent(atob(state)));
      } catch (e) {
        console.error(e);
        return DEFAULT_VALUE;
      }
    }

    return DEFAULT_VALUE;
  };

  const setValue = (value: GuestStateProps) => {
    try {
      storage.setItem(key, btoa(encodeURIComponent(JSON.stringify(value))));
    } catch (e) {
      console.error(e);
    }
  };

  return ({setSelf, onSet, trigger}) => {
    if (trigger === 'get') {
      const initValue = getValue();

      if (initValue !== null) {
        setSelf(initValue);
      }
    }

    onSet((newValue) => {
      if (newValue !== null && newValue !== undefined && newValue instanceof DefaultValue) {
        storage.removeItem(key);
      } else {
        setValue(newValue);
      }
    });
  };
}

//
export const GuestState = atom<GuestStateProps>({
  key: 'GuestState',
  default: DEFAULT_VALUE,
  effects_UNSTABLE: [fromLocalStorageEffect('guest')],
});
